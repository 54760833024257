//eclude from jest coverage
/* istanbul ignore file */
import { Route } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { ResultPageComponent } from './pages/result-page/result-page.component';
import { GreetingsPageComponent } from './pages/greetings-page/greetings-page.component';

export const appRoutes: Route[] = [
  {
    path: '',
    component: SearchPageComponent,
    pathMatch: 'full',
  },
  {
    path: ':propertyCode/:langCode',
    redirectTo: 'search/:propertyCode/:langCode',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'search/:propertyCode/:langCode',
    component: SearchPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'results/:propertyCode/:verificationCode/:firstName/:lastName/:langCode',
    component: ResultPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'greetings/:propertyCode/:langCode/:hasPdfError/:errorObj',
    component: GreetingsPageComponent,
  },
  {
    path: 'auth-error',
    loadComponent: () => import('./pages/error-page/error-page.component').then((m) => m.ErrorPageComponent),
  },
  {
    path: 'no-data-error',
    loadComponent: () => import('./pages/no-data-error-page/no-data-error-page.component').then((m) => m.NoDataErrorPageComponent),
  },
  {
    path: '**',
    loadComponent: () => import('./pages/search-page/search-page.component').then((m) => m.SearchPageComponent),
  },
];
