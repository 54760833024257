/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Guest } from '../../core/types/guest.type';
import { RoomReservation } from '../../core/types/room-reservation.type';
import { ConfigResponse } from '../../core/types/config-response.type';
import { CommonModule } from '@angular/common';
import { DataResumenComponent } from '../data-resumen/data-resumen.component';
import { InfoConfirmationBoxComponent } from '../info-confirmation-box/info-confirmation-box.component';
import { PrivacyItem } from '../../core/types/privacy-item.type';
import { FormGroup } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import jsPDF from 'jspdf';
import { StorageService } from '../../services/storage.service';
import { Vehicle } from '../../core/types/vehicle.type';
import { VehicleType } from '../../core/types/vehicle-type.enum';

@Component({
  selector: 'app-pdf-component',
  templateUrl: './pdf-component.component.html',
  styleUrls: ['./pdf-component.component.css'],
  imports: [CommonModule, HeaderComponent, DataResumenComponent, InfoConfirmationBoxComponent, TranslateModule],
  standalone: true,
})
export class PdfComponentComponent implements OnChanges {
  @Input() guest!: Guest | null;
  @Input() reservation!: RoomReservation;
  @Input() config!: ConfigResponse | null;
  @Input() privacyItems!: PrivacyItem[];
  @Input() form!: FormGroup;
  @Input() formValues!: any;

  pdfFile!: Blob;
  today = new Date();
  isForPdf = false;
  selectedValues = [];
  logoDataUrl!: string | null;
  signatureDataUrl!: string | null;
  doc!: jsPDF;
  errorObj: any;

  constructor(
    private cd: ChangeDetectorRef,
    private tr: TranslateService,
    private storageService: StorageService
  ) {
    this.doc = new jsPDF();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formValues'] && changes['formValues'].currentValue) {
      this.isForPdf = true;
      this.cd.detectChanges();
    }
  }

  getEmailFromForm() {
    return this.formValues ? this.formValues['email'] : '';
  }

  getSelectedValues(index: number) {
    return (
      this.formValues &&
      this.formValues['acceptedPrivacyItems']
        .filter((a: any, i: number) => i !== this.privacyItems.length && i === index)
        .map((a: any) => a.value)
    );
  }

  async generatePDF(): Promise<{
    doc: Blob;
    hasPdfError: boolean;
    errorObj: any;
  }> {
    this.signatureDataUrl = this.storageService.readFromSessionStorage('signatureDataUrl');

    const startX = 10;
    let startY = 20;
    const rowHeight = 8;
    const pageWidth = this.doc.internal.pageSize.width || this.doc.internal.pageSize.getWidth() - startX;

    this.doc.addFont('../../../assets/fonts/Roboto/Roboto-Black.ttf', 'Roboto-Black', 'bold');
    this.doc.addFont('../../../assets/fonts/Roboto/Roboto-Regular.ttf', 'RobotoRegular', 'normal');

    this.doc.setFont('Roboto-Black', 'bold');
    this.doc.setFontSize(12);

    const labels = this.getLabels();
    const data = this.getData();
    let hasPdfError = false;
    //ADD LOGO
    try {
      const logoDataUrl = this.storageService.readFromSessionStorage('logoDataUrl');
      if (logoDataUrl) {
        const img = new Image();
        img.src = logoDataUrl || '';
        img.crossOrigin = 'anonymous';
        const { width, height } = this.doc.getImageProperties(img);
        this.doc.addImage(logoDataUrl, 'PNG', 0, 0, width / 4, height / 4, 'logo');
      } else {
        this.errorObj = { ...this.errorObj, logo: 'Error loading logo IMG' };
        hasPdfError = true;
      }
    } catch (error) {
      this.errorObj = { ...this.errorObj, logo: error };
      console.error('error', error);
      hasPdfError = true;
    }

    try {
      const startXTitle = hasPdfError ? startX : startX + 110;
      const align = hasPdfError ? 'left' : 'center';
      // WRITE PROPERTY NAME
      this.doc.text(this.formValues['propertyName'], startXTitle, startY, {
        align: align,
      });
      startY = startY + rowHeight;
      this.doc.text(this.tr.instant('pdf.title'), startXTitle, startY, {
        align: align,
      });
      startY = startY + 30;
      this.doc.setFont('RobotoRegular', 'normal');
      // WRITE DETAILS LABELS
      let k = 0;
      for (let i = 0; i < labels.length; i++) {
        if (data[i]) {
          this.doc.text(labels[i], startX, startY + k * rowHeight);
          k++;
        }
      }
      // WRITE DETAILS DATA
      k = 0;
      for (let i = 0; i < data.length; i++) {
        let row = data[i];

        if (labels[i] === this.tr.instant('privacy_form.guest_details.stay')) {
          row =
            new Date(this.formValues['checkinDate']).toLocaleDateString('en-GB') +
            ' - ' +
            new Date(this.formValues['checkoutDate']).toLocaleDateString('en-GB');
        }
        if (row) {
          const splittedText = this.doc.splitTextToSize(row, 200);
          this.doc.text(splittedText, 70, startY + k * rowHeight);
          k++;
        }
      }
      const privacyItems = this.config?.privacyItems || [];
      const privacyItemsData = this.formValues['acceptedPrivacyItems'] || [];
      startY = startY + (k + 2) * rowHeight;

      //CREATE FIRST PRIVACY ITEM AND FORMATTED TEXT
      const splittedTextMainPrivacy = this.doc.splitTextToSize(privacyItems[0].text, pageWidth - 25);
      this.doc.text(splittedTextMainPrivacy, startX, startY);
      startY = startY + splittedTextMainPrivacy.length * rowHeight;
      //WRITE PRIVACY ITEMS
      for (let i = 0; i < privacyItemsData.length; i++) {
        const row = privacyItems.find((p) => p.id === privacyItemsData[i].privacyItemId);
        if (row) {
          //LABEL
          this.doc.setFont('Roboto-Black', 'bold');
          const valueSelectedInForm = privacyItemsData[i].label;
          this.doc.text(valueSelectedInForm, startX, startY + 10);
          startY += 10 + rowHeight;
          //TEXT
          this.doc.setFont('RobotoRegular', 'normal');
          const splittedText = this.doc.splitTextToSize(row.text, 200);
          this.doc.text(splittedText, startX, startY);
          startY = startY + splittedText.length + rowHeight;
        }
      }
      startY += rowHeight + 5;
      //WRITE SIGNATURE AND DATE
      const locationCity = this.formValues['_locationCity'] || '';
      const date = new Date().toLocaleDateString('en-GB');
      this.doc.text(locationCity + ', ' + date, startX + 100, startY);
      startY += Math.floor(rowHeight / 2);
    } catch (error) {
      this.errorObj = { ...this.errorObj, details: error };
      console.error('error', error);
    }

    try {
      if (this.signatureDataUrl) {
        const signatureImg = new Image();
        signatureImg.src = this.signatureDataUrl || '';
        signatureImg.crossOrigin = 'anonymous';
        const typeSignature = this.doc.getImageProperties(signatureImg);
        const { width, height } = typeSignature;
        this.doc.addImage(this.signatureDataUrl, 'PNG', 100, startY, width / 4, height / 4, 'Signature');
      }
    } catch (error) {
      this.errorObj = { ...this.errorObj, signature: error };
      console.error('error', error);
      hasPdfError = true;
    }
    const uri = this.doc.output('datauristring');
    this.storageService.writeToSessionStorage('pdfDataUrl', uri);
    this.storageService.writeToSessionStorage('hasPdfError', JSON.stringify(hasPdfError));

    return {
      doc: this.doc.output('blob'),
      hasPdfError: hasPdfError,
      errorObj: this.errorObj,
    };
  }

  private getData() {
    return [
      this.formValues['checkinNumber'] || '',
      this.formValues['firstName'] + ' ' + this.formValues['lastName'],
      this.formValues['email'] || '',
      this.formValues['phone'] || '',
      this.formValues['plate'] || '',
      this.formValues['roomName'] || '',
      this.formValues['checkinDate'] + ' - ' + this.formValues['checkoutDate'],
      this.formValues['adults']
        ? this.formValues['adults'] + ' ' + this.tr.instant('privacy_form.guest_details.adults')
        : '' + this.formValues['children']
          ? ' , ' + this.formValues['children'] + ' ' + this.tr.instant('privacy_form.guest_details.children')
          : '',
      this.formValues['cityTaxAmount'] ? this.formValues['cityTaxAmount'] + this.formValues['currencyCode'] : '',
      this.formValues['_facilities'],
      (this.formValues['vehicles'] || [])
        .map(
          (v: Vehicle) =>
            this.tr.instant('privacy_form.guest_details.plateCode') +
            ': ' +
            v.plateCode +
            ', ' +
            this.tr.instant('privacy_form.guest_details.vehicleType') +
            ' ' +
            this.translateVehicleTypeId(v.typeId)
        )
        .join(', ') || '',
    ];
  }
  translateVehicleTypeId(typeId: VehicleType) {
    const vehicleTypes = Object.keys(VehicleType) as Array<keyof typeof VehicleType>;
    const typeSelected = vehicleTypes.find((key) => VehicleType[key] == typeId);
    return typeSelected;
  }

  private getLabels() {
    const checkin_number = this.tr.instant('privacy_form.guest_details.checkin_number');
    const firstName = this.tr.instant('privacy_form.guest_details.name');
    const email = this.tr.instant('privacy_form.guest_details.email');
    const phone = this.tr.instant('privacy_form.guest_details.phone');
    const plate = this.tr.instant('privacy_form.guest_details.plate');
    const room = this.tr.instant('privacy_form.guest_details.room');
    const stay = this.tr.instant('privacy_form.guest_details.stay');
    const composition = this.tr.instant('privacy_form.guest_details.composition');
    const cityTax = this.tr.instant('privacy_form.guest_details.city_tax');
    const facilities = this.tr.instant('privacy_form.guest_details.facilities');
    const vehicles = this.tr.instant('privacy_form.guest_details.vehicles');
    const adults = this.tr.instant('privacy_form.guest_details.adults');
    const children = this.tr.instant('privacy_form.guest_details.children');
    return [
      checkin_number,
      firstName,
      email,
      phone,
      plate,
      room,
      stay,
      composition,
      cityTax,
      facilities,
      vehicles,
      adults,
      children,
    ];
  }
}
