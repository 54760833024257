<div class="container mt-3">
  @if (guest && reservation) {
  <section id="data-resumen">
    <div class="row">
      <div class="col">
        {{ "privacy_form.guest_details.checkin_number" | translate }}:
      </div>
      <div class="col">
        <span data-result-detail="reservation" *ngIf="reservation.id">{{
          reservation.id
        }}</span>
      </div>
    </div>
    <div class="row my-2">
      <div class="col">
        {{ "privacy_form.guest_details.name" | translate }}:
      </div>
      <div class="col">
        <span *ngIf="reservation?.guests?.length" data-result-detail="name">{{
          (guest.firstName | titlecase) + " " + (guest.lastName | titlecase)
        }}</span>
      </div>
    </div>
    <div class="row my-2" *ngFor="let control of controlArray">
      @if (mustShowFields(control)) {
        <div class="col">
          {{ "privacy_form.guest_details."+ control.name | translate }}:
        </div>

        <div class="col" >
          <div class="col">
            @if (isForPdf) {
            <span>{{formValues[control.name]}}</span>
            }@else {
              @if (control.type === 'select') {
                <select
                    data-input="control.name"
                    *ngIf="control"
                    id="{{control.name}}"
                    name="{{control.name}}"
                    [className]="control.control.errors ? 'form-select  form-select-sm is-invalid' : 'form-select form-select-sm' "
                    [formControl]="control.control"
                  >
                    <option value="0" >{{ 'privacy_form.'+control.name+'_placeholder' | translate }}</option>
                    <option *ngFor="let item of selectsObject[control.name]" [value]="item.value">{{
                      item.type
                    }}</option>
                    {{control.control.value}}
                </select>
              } @else if (control.type === 'text' || control.type === 'tel' || control.type === 'email' )  {
                <input
                  data-input="control.name"
                  *ngIf="control"
                  [className]="control.control.errors ? 'is-invalid' : ''"
                  type="{{control.type}}"
                  id="{{control.name}}"
                  name="{{control.name}}"
                  class="form-control form-control-sm"
                  [formControl]="control.control"
                  placeholder="{{ 'privacy_form.'+control.name+'_placeholder' | translate }}"
                />
              }
            }
          </div>
        </div>

      }

    </div>
    <hr class="my-4" />
    <div class="row">
      <div class="col">
        {{ "privacy_form.guest_details.room" | translate }}:
      </div>
      <div class="col">
        <span data-result-detail="roomName" *ngIf="reservation?.roomName">{{
          reservation.roomName
        }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col">
        {{ "privacy_form.guest_details.stay" | translate }}:
      </div>
      <div class="col">
        <span
          data-result-detail="stay"
          *ngIf="guest.checkoutDate || guest?.checkinDate"
          >{{ guest.checkinDate | date : "dd/MM/YYYY" }} -
          {{ guest.checkoutDate | date : "dd/MM/YYYY" }}</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        {{ "privacy_form.guest_details.composition" | translate }}:
      </div>
      <div class="col">
        <span *ngIf="reservation?.adults" data-result-detail="composition"
          >{{ reservation.adults }}
          {{ "privacy_form.guest_details.adults" | translate }}</span
        >
        <span *ngIf="reservation?.children">
          +
          {{ reservation.children }}
          {{ "privacy_form.guest_details.children" | translate }}</span
        >
      </div>
    </div>
    <div class="row" *ngIf="guest?.cityTaxAmount">
      <div class="col">
        {{ "privacy_form.guest_details.city_tax" | translate }}:
      </div>
      <div class="col">
        <span>{{
          guest.cityTaxAmount
            | currency : guest.currencyCode || "EUR" : "symbol"
        }}</span>
      </div>
    </div>
    <div class="row mt-2" *ngIf="reservation?.facilityIds?.length">
      <div class="col">
        {{ "privacy_form.guest_details.facilities" | translate }}:
      </div>
      <div class="col">
        <span>
          {{ getFacilities(reservation.facilityIds) }}
        </span>
      </div>
    </div>
  </section>
  }
</div>
