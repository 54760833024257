//eclude from jest coverage
/* istanbul ignore file */
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private jwtHelper: JwtHelperService
  ) {}

  async redirectDependingOnToken(token: string | null) {
    if (this.jwtHelper.isTokenExpired(token)) {
      await this.router.navigate(['/auth-error'], { relativeTo: this.route });
    } else {
      await this.router.navigate(['/login'], { relativeTo: this.route });
    }
  }

  async init(): Promise<void> {
    /* const token =
      this.getTokenFromUrlParams() || this.getTokenFromSessionStorage();

        if (token) {
      sessionStorage.setItem('token', token);
      if (this.isHomePage()) {
        await this.redirectDependingOnToken(token);
      }
    } else {
      await this.router.navigate(['/auth-error'], { relativeTo: this.route });
    } */
  }

  private getTokenFromUrlParams(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('accessToken');
  }

  private getTokenFromSessionStorage(): string | null {
    return sessionStorage.getItem('token');
  }

  private isHomePage(): boolean {
    return window.location.pathname === '/';
  }
}
