import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import posthog from 'posthog-js';
import { environment } from './environments/environment';
if (environment.POSTHOG_KEY && environment.POSTHOG_HOST) {
  posthog.init(environment.POSTHOG_KEY || '', {
    api_host: environment.POSTHOG_HOST,
  });
}
bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
