import { Injectable } from '@angular/core';
import { HttpBackend, HttpRequest } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  blobFile!: Blob;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend // add this one
  ) {}

  setBlobFile(blobFile: Blob) {
    this.blobFile = blobFile;
  }

  getBlobFile() {
    return this.blobFile;
  }

  uploadfileAWSS3(fileuploadurl: string, file: Blob): Observable<unknown> {
    this.http = new HttpClient(this.handler); // to reset the header
    const headers = new HttpHeaders({ 'Content-Type': file.type });
    const req = new HttpRequest('PUT', fileuploadurl, file, {
      headers: headers,
      reportProgress: true,
    });
    return this.http.request(req);
  }

  downloadPdf(fileName: string, blobFile: Blob) {
    const mydate = new Date();
    const curr_date = mydate.getDate();
    const curr_month = mydate.getMonth();
    const curr_year = mydate.getFullYear();

    const blobUrl = URL.createObjectURL(blobFile);

    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.download =
      fileName + '_' + curr_date + '-' + curr_month + '-' + curr_year + '.pdf';

    document.body.appendChild(anchor);

    anchor.click();

    document.body.removeChild(anchor);

    URL.revokeObjectURL(blobUrl);
  }
}
