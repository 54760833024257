import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { GetRoomReservationResponse } from '../../core/types/room-reservation-response.type';
import { CommonModule } from '@angular/common';
import { Guest } from '../../core/types/guest.type';
import { Facility } from '../../core/types/facility.type';
import { LOCALE_ID } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { TranslateModule } from '@ngx-translate/core';
import { ReservationService } from '../../services/reservation.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { VehicleType } from '../../core/types/vehicle-type.enum';
import { ExtraField } from '../../core/types/property.type';

@Component({
  selector: 'app-data-resumen',
  templateUrl: './data-resumen.component.html',
  styleUrls: ['./data-resumen.component.css'],
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule],
  providers: [{ provide: LOCALE_ID, useValue: 'it-IT' }, FormGroupDirective],
})
export class DataResumenComponent implements OnInit, OnDestroy, OnChanges {
  @Input() reservation!: GetRoomReservationResponse | null;
  @Input() guest!: Guest | null;
  @Input() isForPdf = false;
  @Input() controlArray!: { name: string; type: string; control: FormControl }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() formValues!: any;
  guests: Guest[] = [];
  facilityIds: number[] = [];
  facilities: Facility[] = [];
  subscription = new Subscription();
  emailFromForm!: string;
  phoneFromForm!: string;
  plateFromForm!: string;
  vehicleTypeFromForm!: string;
  JSON = JSON;
  vehicleTypes: { type: string; value: VehicleType }[] = [];
  maxNumberOfVehicles: number = 1;
  selectsObject: {
    [key: string]: { type: string; value: VehicleType }[];
  } = {
    vehicleType: [],
  };
  extrafields: ExtraField[] = [];
  vehiclesFields = ['plate', 'vehicleType'];

  constructor(
    private configurationService: ConfigurationService,
    private reservationService: ReservationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formValues'] && changes['formValues'].currentValue) {
      this.emailFromForm = this.formValues.email;
      this.phoneFromForm = this.formValues.phone;
      this.plateFromForm = this.formValues.plate;
      this.vehicleTypeFromForm = VehicleType[this.formValues.vehicleType];
      this.cd.detectChanges();
    }
  }

  ngOnInit() {
    this.subscribetoConfig();
    this.subscribeToReservation();
    this.vehicleTypes = this.initVehicleTypes();
    this.selectsObject['vehicleType'] = this.vehicleTypes;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initVehicleTypes(): { type: string; value: VehicleType }[] {
    const vehicleTypesKeys = Object.keys(VehicleType) as Array<keyof typeof VehicleType>;
    return (
      vehicleTypesKeys
        .filter((value) => isNaN(parseInt(value)))
        .map((key) => ({ type: key, value: VehicleType[key] })) || []
    );
  }

  isFieldActive(fieldName: string): boolean {
    return this.extrafields.some(
      (extraField) => extraField.name?.toLowerCase() === fieldName.toLowerCase() && extraField.isVisible
    );
  }

  mustShowFields(control: { name: string }): boolean {
    return (
      !this.vehiclesFields.includes(control.name) ||
      (this.vehiclesFields.includes(control.name) && this.isFieldActive('vehicles'))
    );
  }

  subscribeToReservation() {
    this.subscription.add(
      this.reservationService.reservation$.subscribe((reservation) => {
        if (reservation) {
          this.guests = this.reservation?.guests || [];
          this.facilityIds = this.reservation?.facilityIds || [];
        }
      })
    );
  }

  subscribetoConfig() {
    this.subscription.add(
      this.configurationService.config$.subscribe((config) => {
        if (config) {
          //this.maxNumberOfVehicles = config.property.maxNumberOfvehicles;
          this.extrafields = config.property.extraFields || [];
          this.facilities = config.property.facilities || [];
        }
      })
    );
  }

  getFacilities(facilityIds: number[]): string {
    return facilityIds
      .map((facilityId) => this.facilities.find((facility) => facility.id === facilityId)?.text)
      .filter((x) => x)
      .join(', ');
  }
}
