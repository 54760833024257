<div class="container">
  <div class="jumbotron mt-5">
    <h1 data-message="title" class="display-4">
      {{ "greetings.title" | translate }}
    </h1>
    <p data-message="subtitle" class="lead">
      {{ "greetings.message" | translate }}
    </p>
    <hr class="my-4" />
    <p data-message="message">
      {{ "greetings.second_message" | translate }}
    </p>
  </div>
  <div class="d-flex justify-content-around">
    <button
      class="btn btn-primary"
      (click)="goToSearch()"
      data-button="go-to-search"
    >
      {{ "greetings.go_to_search" | translate }}
    </button>

    <button
      *ngIf="blobFile"
      class="btn btn-primary"
      [ngClass]="{ 'border-red': hasPdfError }"
      (click)="downloadPdf()"
    >
      {{ "greetings.download_pdf" | translate }}
    </button>
  </div>
  @if (hasPdfError) {
  <div class="error-text">
    {{ "privacy_form.error_generating_pdf" | translate }}
    @if (errorObj) {
    <div class="error-text">
      {{ errorObj }}
    </div>
    }
  </div>
  }
</div>
