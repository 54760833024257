import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReservationService } from '../../services/reservation.service';
import { HeaderComponent } from '../../components/header/header.component';
import { GetRoomReservationResponse } from '../../core/types/room-reservation-response.type';
import { ActivatedRoute, Router } from '@angular/router';
import { Guest } from '../../core/types/guest.type';
import { PrivacyItem } from '../../core/types/privacy-item.type';
import { LoaderComponent } from '../../components/loader/loader.component';
import { ConfigResponse } from '../../core/types/config-response.type';
import {
  FormGroup,
  FormBuilder,
  ReactiveFormsModule,
  AbstractControl,
  Validators,
  ValidatorFn,
  FormControl,
} from '@angular/forms';
import { InfoConfirmationBoxComponent } from '../../components/info-confirmation-box/info-confirmation-box.component';
import { DataResumenComponent } from '../../components/data-resumen/data-resumen.component';
import { Subscription, lastValueFrom } from 'rxjs';
import { UploadService } from '../../services/upload.service';
import { sanitizeString } from '../../utils';
import { ConfigurationService } from '../../services/configuration.service';
import { TranslateModule } from '@ngx-translate/core';
import { PdfComponentComponent } from '../../components/pdf-component/pdf-component.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { StorageService } from '../../services/storage.service';
import { RoomReservation } from '../../core/types/room-reservation.type';
import { VehicleType } from '../../core/types/vehicle-type.enum';
import { GuestItem } from '../../core/types/guest-item.type';

@Component({
  selector: 'app-result-page',
  templateUrl: './result-page.component.html',
  styleUrls: ['./result-page.component.css'],
  imports: [
    HeaderComponent,
    InfoConfirmationBoxComponent,
    DataResumenComponent,
    LoaderComponent,
    ReactiveFormsModule,
    TranslateModule,
    PdfComponentComponent,
    CommonModule,
    FontAwesomeModule,
  ],
  standalone: true,
})
export class ResultPageComponent implements OnInit, OnDestroy {
  @Input() propertyCode!: string;
  @Input() firstName!: string;
  @Input() lastName!: string;
  @Input() verificationCode!: string;
  @Input() langCode: string = 'it_IT';

  private subscription = new Subscription();
  private propertyId!: number | undefined;
  private isFirstDraw = true;
  private lastX!: number;
  private lastY!: number;

  //SIGNATURE
  @ViewChild('signatureCanvas') signatureCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('formContainer') formContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('pdfComponent') pdfComponent!: PdfComponentComponent;

  isSigning = false;
  isSigned = false;

  isUploading = false;
  canvasBorderStyle = '1px solid red';

  signature = '';
  signatureImage!: ElementRef<HTMLImageElement>;
  formValues!: unknown;
  hasFoundGuest = false;
  error!: { title: string; message: string };
  isPdfComponentVisible!: boolean;
  visiblePrivacyItems: number[] = [];
  notTextPrivacyItems: PrivacyItem[] = [];

  reservation!: GetRoomReservationResponse;
  config!: ConfigResponse | null;
  guest!: Guest;
  privacyItems: PrivacyItem[] = [];
  form!: FormGroup;
  hasResults!: boolean;
  isLoading = true;
  faTrash = faTrash;
  controlsArrayForResumen: { name: string; type: string; label: string; control: FormControl; isVisible: boolean }[] =
    [];
  guestItems: GuestItem[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private configurationService: ConfigurationService,
    private reservationService: ReservationService,
    private router: Router,
    private storageService: StorageService,
    private fb: FormBuilder,
    private uploadService: UploadService,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({});
  }

  ngOnInit() {
    this.subscribeToRouteChange();
    this.subscribeToConfig();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private subscribeToRouteChange() {
    this.subscription.add(
      this.route.params.subscribe((params) => {
        const { propertyCode, langCode } = params;
        if (propertyCode && langCode) {
          this.propertyCode = propertyCode;
          this.configurationService.setPropertyCode(propertyCode);
          this.langCode = langCode;
          this.configurationService.setLangCode(langCode);
        }
      })
    );
  }

  private subscribeToConfig() {
    this.subscription.add(
      this.configurationService.config$.subscribe({
        next: (config) => {
          if (config) {
            this.config = config;
            this.guestItems = config?.guestItems ?? [];
            this.propertyId = config?.property.id;
            if (config?.privacyItems.length) {
              this.privacyItems = config?.privacyItems ?? [];
            }
            this.privacyItems = this.config?.privacyItems ?? [];
            this.notTextPrivacyItems = this.privacyItems.filter((p) => p.type !== 'text');
            this.cd.detectChanges();
            this.initReservation();
          }
        },
      })
    );
  }
  initGuestItemsControls() {
    this.guestItems.forEach((guestItem: GuestItem) => {
      if (guestItem.isVisible) {
        const control = this.form.controls[guestItem.name];
        this.form.addControl(guestItem.name, control);
        const controlValue = this.controlsArrayForResumen.find((c) => c.name === guestItem.name);
        if (controlValue) {
          controlValue.control = this.convertToFormControl(control);
        } else {
          this.controlsArrayForResumen.push({
            name: guestItem.name,
            type: guestItem.type,
            label: guestItem.label,
            isVisible: guestItem.isVisible,
            control: this.convertToFormControl(control),
          });
        }
      }
    });
  }

  private initReservation() {
    const reservation = this.setReservationFromStorage();
    if (reservation?.id) {
      this.setReservation(reservation);
      this.initForm();
      this.initGuestItemsControls();
    } else {
      this.setReservationFromService();
    }
  }

  private setReservationFromStorage(): RoomReservation | undefined {
    const reservation = this.storageService.readFromSessionStorage('reservation');
    if (reservation) {
      const reservationParsed = JSON.parse(reservation);
      if (reservationParsed.verificationCode === this.verificationCode) {
        return reservationParsed;
      }
      return;
    } else {
      return;
    }
  }

  private setReservationFromService() {
    this.subscription.add(
      this.reservationService
        .getRoomReservation(this.propertyCode, this.verificationCode, this.firstName, this.lastName)
        .subscribe({
          next: (reservation) => {
            if (reservation) {
              this.setReservation(reservation);
              this.initForm();
              this.initGuestItemsControls();
            }
          },
          error: (error) => {
            this.handleReservationError();
            this.cd.detectChanges();
            console.error('Error:', error);
          },
          complete: () => {
            if (!this.reservation) {
              this.handleReservationError();
              this.cd.detectChanges();
            }
          },
        })
    );
  }

  private setReservation(reservation: GetRoomReservationResponse) {
    this.reservation = reservation;
    this.reservationService.setReservation(reservation);
    this.hasResults = true;
    this.guest = reservation.guests?.filter(
      (g) =>
        sanitizeString(g.firstName) === sanitizeString(this.firstName) &&
        sanitizeString(g.lastName) === sanitizeString(this.lastName)
    )[0];
    const facilities = this.config?.property.facilities
      .filter((f) => this.guest.facilityIds?.includes(f.id))
      .map((f) => f.text)
      .join(', ');
    this.form.get('_facilities')?.setValue(facilities);
    this.storageService.writeToSessionStorage('reservation', JSON.stringify(reservation));
    this.isLoading = false;
    this.hasFoundGuest = !!this.guest;
  }

  private handleReservationError() {
    this.hasResults = false;
    this.error = {
      title: 'privacy_form.no_reservation_found_error_title',
      message: 'privacy_form.no_reservation_found_error_message',
    };
    this.isLoading = false;
  }

  private initForm() {
    this.form = this.fb.group({
      id: this.fb.control(this.guest?.id ?? null),
      checkinNumber: this.fb.control(this.reservation?.id ?? ''),
      firstName: this.fb.control(this.guest?.firstName ?? '', Validators.required),
      lastName: this.fb.control(this.guest?.lastName ?? '', Validators.required),
      email: this.fb.control(this.guest?.email ?? null, [
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      verificationCode: this.fb.control(this.verificationCode, Validators.required),
      roomName: this.fb.control(this.reservation.roomName ?? ''),
      checkinDate: this.fb.control(this.guest?.checkinDate ?? ''),
      checkoutDate: this.fb.control(this.guest?.checkoutDate ?? ''),
      cityTaxAmount: this.fb.control(this.guest?.cityTaxAmount ?? 0),
      currencyCode: this.fb.control(this.guest?.currencyCode ?? 'EUR'),
      facilityIds: this.fb.control(this.guest?.facilityIds ?? []),
      adults: this.fb.control(this.reservation?.adults ?? 0),
      children: this.fb.control(this.reservation?.children ?? 0),
      vehicles: this.fb.array(this.guest?.vehicles ?? []),
      propertyCode: this.fb.control(this.propertyCode),
      propertyName: this.fb.control(this.config?.property.name ?? ''),
      acceptedPrivacyItems: this.fb.control([], this.arrayValidator()),
      plateCode: this.fb.control({
        value: this.guest?.vehicles?.length ? this.guest?.vehicles[0]?.plateCode || '' : null,
        disabled: this.guest?.vehicles?.length ? !!this.guest?.vehicles[0]?.typeId : false,
      }),
      phone: this.fb.control(this.guest?.phone ?? ''),
      vehicleType: this.fb.control({
        value: this.guest?.vehicles?.length ? this.guest?.vehicles[0]?.typeId || '' : 0,
        disabled: this.guest?.vehicles?.length ? !!this.guest?.vehicles[0]?.typeId : false,
      }),
      sendMeCopy: this.fb.control(false),
      _facilities: this.fb.control(''),
      _isSigned: this.fb.control(false, Validators.requiredTrue),
      _signatureRaw: this.fb.control(''),
      _locationCity: this.fb.control(this.config?.locationCity ?? ''),
    });
  }

  convertToFormControl(absCtrl: AbstractControl | null): FormControl {
    const ctrl = absCtrl as FormControl;
    return ctrl;
  }

  private arrayValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const formValues = control.value as {
        privacyItemId: number;
        value: boolean;
      }[];
      let isRequired = false;
      formValues.forEach((item) => {
        const privacyItem = this.privacyItems.find((privacyItem) => {
          return privacyItem.id === item.privacyItemId;
        });
        isRequired = !!privacyItem?.required;
      });

      const privacyItemsWithOptions = this.privacyItems.filter((privacyItem) => privacyItem.type !== 'text');
      const privacyItemsWithOptionsLength = privacyItemsWithOptions.length;

      const allValuesTrue = formValues.every((item) => item.value);
      if (formValues?.length < privacyItemsWithOptionsLength || (!allValuesTrue && isRequired)) {
        return { invalidValues: true };
      }

      return null;
    };
  }

  shouldShowItem(itemId: number) {
    return itemId === this.notTextPrivacyItems[0]?.id || this.visiblePrivacyItems.includes(itemId);
  }

  areAllBoxedClicked() {
    return this.visiblePrivacyItems.length >= this.notTextPrivacyItems.length;
  }

  onSelectOption(privateItemId: number) {
    if (!this.visiblePrivacyItems.includes(privateItemId)) this.visiblePrivacyItems.push(privateItemId);
  }

  setError() {
    this.error = {
      title: 'privacy_form.no_config_found_error_title',
      message: 'privacy_form.no_config_found_error_message',
    };
  }

  startSigning(event: MouseEvent | TouchEvent): void {
    event.preventDefault();
    this.setIsSignedFormValue(true);
    this.canvasBorderStyle = '1px solid grey';
    this.isSigning = true;

    const canvas = this.signatureCanvas.nativeElement;
    const ctx = canvas.getContext('2d');

    const rect = canvas.getBoundingClientRect();
    this.lastX = event instanceof MouseEvent ? event.clientX - rect.left : event.touches[0].clientX - rect.left;
    this.lastY = event instanceof MouseEvent ? event.clientY - rect.top : event.touches[0].clientY - rect.top;
    if (!ctx) return;
    // Remove the clearRect call
    if (this.isFirstDraw) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.isFirstDraw = false;
    }
    ctx.beginPath();
    ctx.moveTo(this.lastX, this.lastY);

    // Add focus event listener
    canvas.addEventListener('focus', () => {
      if (this.isSigning) {
        this.signing(event);
      }
    });
  }

  stopSigning(): void {
    if (!this.isSigning && this.isSigned) {
      this.canvasBorderStyle = '1px solid grey';
    } else {
      this.canvasBorderStyle = '1px solid red';
    }
    this.isSigning = false;
    this.isSigned = true;
  }

  signing(event: MouseEvent | TouchEvent): void {
    event.preventDefault();
    if (!this.isSigning) return;

    const canvas = this.signatureCanvas.nativeElement;
    const ctx = canvas.getContext('2d');

    const rect = canvas.getBoundingClientRect();
    const mouseX = event instanceof MouseEvent ? event.clientX - rect.left : event.touches[0].clientX - rect.left;
    const mouseY = event instanceof MouseEvent ? event.clientY - rect.top : event.touches[0].clientY - rect.top;
    if (!ctx) return;
    ctx.lineTo(mouseX, mouseY);
    ctx.stroke();

    this.lastX = mouseX;
    this.lastY = mouseY;
  }

  resetCanvas(): void {
    const canvas = this.signatureCanvas.nativeElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Redraw the default text
    this.isFirstDraw = true;
    this.isSigned = false;
    this.canvasBorderStyle = '1px solid red';
    this.setIsSignedFormValue(false);
  }

  createResultObjectForPdf(): unknown {
    const formValues = this.form.value;
    //Aggiungo un elemento al principio per stampare il PDF e mettere i check agli items corrispondenti
    formValues.acceptedPrivacyItems.unshift({});
    return formValues;
  }

  async onSubmit(): Promise<void> {
    await this.sendFormAndSendPDF();
  }

  private async sendFormAndSendPDF(): Promise<void> {
    const canvas = this.signatureCanvas.nativeElement;
    const dataUrl = canvas.toDataURL('image/png', 1.0);
    this.storageService.writeToSessionStorage('signatureDataUrl', dataUrl);

    this.form.controls['_signatureRaw']?.setValue(dataUrl);
    this.formValues = this.createResultObjectForPdf();
    this.pdfComponent.formValues = this.formValues;
    this.cd.detectChanges();

    this.isUploading = true;
    let uploadUrl;
    let pdf;
    let hasPdfError = false;
    let errorObj;
    try {
      uploadUrl = await this.sendForm();
      if (!uploadUrl) throw new Error('No upload url found, Post was not successful');
    } catch (error) {
      this.isUploading = false;
      this.error = error as { title: string; message: string };
    }

    this.isPdfComponentVisible = true;

    try {
      const result = await this.pdfComponent.generatePDF();
      pdf = result.doc;
      hasPdfError = result.hasPdfError;
      errorObj = result.errorObj;
    } catch (error) {
      this.isUploading = false;
    }

    this.isPdfComponentVisible = false;
    if (uploadUrl && pdf) await lastValueFrom(this.uploadService.uploadfileAWSS3(uploadUrl, pdf));
    this.router.navigate([
      '/greetings/' + this.propertyCode + '/' + this.langCode + '/' + hasPdfError + '/' + errorObj || '',
    ]);
    this.isUploading = false;
  }

  private async sendForm(): Promise<string | null> {
    const data = this.form.value;
    if (data.plateCode) {
      data.vehicles = [
        {
          id: data.vehicles[0]?.id,
          plateCode: data.plateCode,
          typeId: data.vehicleType,
          name: VehicleType[data.vehicleType],
        },
      ];
    }
    delete data.plateCode;
    delete data.vehicleType;
    try {
      const response = await lastValueFrom(this.reservationService.sendResults(data));
      if (response) {
        const { uploadUrl } = response;
        return uploadUrl;
      }
      return null;
    } catch (error) {
      this.isUploading = false;
      this.error = {
        title: 'privacy_form.error_sending_form_title',
        message: 'privacy_form.error_sending_form_message',
      };
      this.cd.detectChanges();
      return null;
    }
  }

  setIsSignedFormValue(value: boolean): void {
    this.form.controls['_isSigned']?.setValue(value);
  }

  goToSearch(): void {
    const path = '/search/' + this.propertyCode + '/' + (this.langCode || 'it_IT');
    this.router.navigate([path]);
  }
}
