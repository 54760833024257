import { Component } from '@angular/core';
import { version } from '../../../../package.json';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  standalone: true,
})
export class FooterComponent {
  version!: string;
  constructor() {
    this.version = version;
  }
}
