<div class="container">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="username">Username</label>
      <input
        type="text"
        id="username"
        class="form-control"
        formControlName="username"
        required
      />
    </div>

    <div class="form-group">
      <label for="password">Password</label>
      <input
        type="password"
        id="password"
        class="form-control"
        formControlName="password"
        required
      />
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid">
      Login
    </button>
  </form>
</div>
