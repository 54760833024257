import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from '../../components/header/header.component';
import { UploadService } from '../../services/upload.service';
import { ConfigurationService } from '../../services/configuration.service';
import { ConfigResponse } from '../../core/types/config-response.type';
import { ReservationService } from '../../services/reservation.service';
import { GetRoomReservationResponse } from '../../core/types/room-reservation-response.type';
import { CommonModule } from '@angular/common';
import { StorageService } from '../../services/storage.service';
import { dataURItoBlob } from '../../utils';

@Component({
  selector: 'app-greetings-page',
  templateUrl: './greetings-page.component.html',
  styleUrls: ['./greetings-page.component.css'],
  standalone: true,
  imports: [RouterModule, TranslateModule, HeaderComponent, CommonModule],
})
export class GreetingsPageComponent implements OnInit {
  @Input() propertyCode!: string;
  @Input() langCode: string = 'It_IT';
  subscription = new Subscription();
  config!: ConfigResponse;
  reservation!: GetRoomReservationResponse;
  blobFile!: Blob;
  hasPdfError = false;
  errorObj: unknown;
  json!: JSON;

  constructor(
    private router: Router,
    private uploadService: UploadService,
    private configService: ConfigurationService,
    private reservationService: ReservationService,
    private storageService: StorageService,
    private route: ActivatedRoute
  ) {
    this.json = JSON;
  }

  ngOnInit() {
    this.subscribeToRouteChange();
    this.subscribeToConfig();
    this.subscribeToReservation();
    this.retrievePdfBlobFile();
  }

  private subscribeToRouteChange() {
    this.subscription.add(
      this.route.params.subscribe((params) => {
        const { propertyCode, langCode, hasPdfError, errorObj } = params;
        if (propertyCode && langCode) {
          this.propertyCode = propertyCode;
          this.configService.setPropertyCode(propertyCode);
          this.langCode = langCode;
          this.configService.setLangCode(langCode);
        }
        if (hasPdfError) {
          this.hasPdfError = JSON.parse(hasPdfError);
        }
        if (errorObj) {
          this.errorObj = errorObj;
        }
      })
    );
  }

  retrievePdfBlobFile() {
    try {
      const pdfUri = this.storageService.readFromSessionStorage('pdfDataUrl');
      const hasPdfError = this.storageService.readFromSessionStorage('hasPdfError');
      if (pdfUri) {
        this.blobFile = dataURItoBlob(pdfUri);
      }
      if (hasPdfError) this.hasPdfError = JSON.parse(hasPdfError);
    } catch (error) {
      console.error('Error retrieving pdf blob file', error);
    }
  }

  subscribeToReservation() {
    this.subscription.add(
      this.reservationService.reservation$.subscribe((reservation) => {
        if (reservation) {
          this.reservation = reservation;
        }
      })
    );
  }
  subscribeToConfig() {
    this.subscription.add(
      this.configService.config$.subscribe((config) => {
        if (config) this.config = config;
      })
    );
  }

  goToSearch() {
    const path = '/search/' + this.propertyCode + '/' + this.langCode || 'it_IT';
    this.router.navigate([path]);
  }

  downloadPdf() {
    if (!this.reservation) {
      const reservationFromStorage = this.storageService.readFromSessionStorage('reservation');
      if (reservationFromStorage) this.reservation = JSON.parse(reservationFromStorage);
    }
    this.uploadService.downloadPdf(
      this.config.property.name +
        '_' +
        this.reservation.guests[0].firstName +
        '-' +
        this.reservation.guests[0].lastName,
      this.blobFile
    );
  }
}
