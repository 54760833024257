/* eslint-disable @typescript-eslint/no-explicit-any */
//eclude from jest coverage
/* istanbul ignore file */
import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';

export const authErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<any> => {
  return next(req).pipe(
    catchError(async (error) => {
      return error;
    })
  );
};
