import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../components/header/header.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LangCode } from '../../core/types/langCode.enum';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [CommonModule, HeaderComponent, ReactiveFormsModule],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.css',
})
export class LoginPageComponent {
  route = inject(ActivatedRoute);
  //propertyCode = TenantId + propertyId
  propertyCode = '1';
  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });
  langCode: LangCode = LangCode.ItIT;
  constructor(private fb: FormBuilder, private router: Router) {}

  onSubmit(): void {
    if (this.loginForm.valid) {
      //TODO Perform login logic here
      this.router.navigate([
        '/search/' + this.propertyCode + '/' + this.langCode,
      ]);
    }
  }
}
