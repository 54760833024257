<div
  class="container d-flex flex-column justify-content-around"
  id="results"
  #formContainer
  [formGroup]="form"
>
  @if (error) {
  <div
    class="container d-flex flex-column align-items-center justify-content-center error-div"
  >
    <div class="text-danger mb-3">{{ error.title | translate }}</div>
    <div class="text-danger mb-5">
      {{ "privacy_form.error" | translate }} : {{ error.message | translate }}
    </div>
    <button class="btn btn-primary" (click)="goToSearch()">
      {{ "privacy_form.back_tutton_text" | translate }}
    </button>
  </div>
  }@else { @if (hasResults) { @if (hasFoundGuest) {
  <div
    class="alert alert-secondary mt-3"
    role="alert"
    *ngIf="config?.welcomeText"
  >
    {{ config?.welcomeText }}
  </div>
  <app-data-resumen
    #reservationDetails
    [guest]="guest"
    [controlArray]="controlsArrayForResumen"
    [reservation]="reservation"
  ></app-data-resumen>

  @for (privacyItem of privacyItems; track $index) {
  <app-info-confirmation-box
    [privacyItem]="privacyItem"
    [id]="privacyItem.id"
    [privacyItemIndex]="$index"
    [form]="form"
    (isSelected)="onSelectOption($event)"
  ></app-info-confirmation-box>
  }
  <div
    class="alert alert-danger d-flex justify-content-center"
    *ngIf="!areAllBoxedClicked()"
  >
    {{ "privacy_form.fill_the_form" | translate }}
  </div>
  <div
    class="alert alert-danger d-flex justify-content-center"
    *ngIf="form.controls['email']?.invalid && areAllBoxedClicked()"
  >
    {{ "privacy_form.provide_correct_email" | translate }}
  </div>
  <div *ngIf="areAllBoxedClicked()">
    <div class="canvas-container">
      <div>
        <div>
          {{ "privacy_form.signature_placeholder" | translate }}
        </div>
        <div class="canvas-box-container">
          <canvas
            #signatureCanvas
            id="signatureCanvas"
            width="350"
            height="200"
            [style.border]="canvasBorderStyle"
            (mousedown)="startSigning($event)"
            (mouseup)="stopSigning()"
            (mouseleave)="stopSigning()"
            (touchstart)="startSigning($event)"
            (touchend)="stopSigning()"
            (mousemove)="signing($event)"
            (touchmove)="signing($event)"
            style="cursor: crosshair; background-color: white"
            data-input="signature-canvas"
          ></canvas>
          <div
            class="reset-button-div"
            [hidden]="isSigning"
            (click)="resetCanvas()"
            (keyup)="resetCanvas()"
            tabindex="0"
          >
            <fa-icon [icon]="faTrash" size="lg"></fa-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-around mt-3 mb-5">
      <button
        (click)="goToSearch()"
        data-button="back-button"
        class="btn btn-sm btn-primary btn-sm"
      >
        {{ "privacy_form.back" | translate }}
      </button>
      <button
        (click)="onSubmit()"
        data-button="submit-button"
        class="btn btn-primary btn-sm"
        [disabled]="!form.valid"
      >
        &nbsp;&nbsp;&nbsp;&nbsp;{{
          "privacy_form.submit" | translate
        }}&nbsp;&nbsp;&nbsp;&nbsp;
      </button>
    </div>
  </div>
  } @else {
  <div class="no-reservation-found">
    <div class="d-flex justify-content-center my-30">
      {{ "privacy_form.reservation_not_found" | translate }}
    </div>
    <div class="d-flex justify-content-center">
      <button class="my-30 btn btn-primary" (click)="goToSearch()">
        {{ "greetings.go_to_search" | translate }}
      </button>
    </div>
  </div>
  } }}
</div>

<app-pdf-component
  [hidden]="!isPdfComponentVisible"
  #pdfComponent
  [guest]="guest"
  [reservation]="reservation"
  [config]="config"
  [privacyItems]="privacyItems"
  [formValues]="formValues"
></app-pdf-component>

<div id="modal" *ngIf="isUploading || isLoading">
  <app-loader></app-loader>
</div>
